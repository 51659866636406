




























import Vue from "vue";
import { mapGetters } from "vuex";
import CalendarHelper from "@/helpers/CalendarHelper";
const calendarHelper = new CalendarHelper();

export default Vue.extend({
  props: {
    appointments: calendarHelper.getAppointmentsPropValidator(),
  },
  data() {
    return {
      format: "weekly",
      daysTurn: 7,
      currentDay: calendarHelper.getFirstAppointment(this.appointments),
    };
  },
  computed: {
    ...mapGetters(["getOverlappingTimes"]),
    monthToDisplay(): string {
      return calendarHelper.getMomentWithFormat(this.currentDay).format("MMMM YYYY");
    },
    weekToDisplay(): string {
      this.setDaysTurn();
      const dayMoment = calendarHelper.getMomentWithFormat(this.currentDay);
      const startOfWeek = dayMoment.clone();
      const endOfWeek = dayMoment.clone().add(this.daysTurn, "days");
      if (this.daysTurn === 0) {
        return calendarHelper.getFormattedDateForDisplay(startOfWeek);
      }
      return (
        startOfWeek.format("DD.MM.") + " - " + calendarHelper.getFormattedDateForDisplay(endOfWeek)
      );
    },
  },
  methods: {
    stepTo(n: number) {
      const dayMoment = calendarHelper.getMomentWithFormat(this.currentDay);
      this.currentDay = calendarHelper.getFormattedDate(dayMoment
        .add(n * this.daysTurn + 1 * n, "days"));
      this.$emit("changeDate", this.currentDay);
    },
    changeFormat(format: "weekly" | "monthly") {
      this.format = format;

      this.$emit("changeFormat", this.format);
    },
    setDaysTurn: function (days?: number) {
      if (days) {
        this.daysTurn = days;
        return;
      }
      this.daysTurn = calendarHelper.calendarPagerDaysTurn(
        this.getOverlappingTimes
      );
    },
  },
});
